import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { createStore } from 'redux';
import { createFirestoreInstance } from 'redux-firestore';
import { rootReducer } from './reducers';
import { checkIsClient } from '../utils';

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_APIKEY,
  appId: process.env.GATSBY_FIREBASE_APPID,
  authDomain: process.env.GATSBY_FIREBASE_AUTHDOMAIN,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENTID,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGINGSENDERID,
  projectId: process.env.GATSBY_FIREBASE_PROJECTID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGEBUCKET,
};

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

console.info('use environment', process.env.NODE_ENV);
console.info('use project', process.env.GATSBY_FIREBASE_PROJECTID);
console.info('use emulator', process.env.GATSBY_USE_EMULATOR);
if (process.env.GATSBY_USE_EMULATOR) {
  firebase.firestore().useEmulator('localhost', 8080);
  firebase.functions().useEmulator('localhost', 5001);
  firebase.auth().useEmulator('http://localhost:9099');
}

if (checkIsClient()) {
  firebase.auth().onAuthStateChanged(authUser => {
    if (authUser) localStorage.setItem('currentUser', authUser.uid);
    else localStorage.removeItem('currentUser');
  });
}

const initialState = {};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const store = createStore(rootReducer, initialState);
export const functions = {
  sendNotificationFn: firebase.functions().httpsCallable('sendNotification'),
  updateCallLogsFn: firebase.functions().httpsCallable('updateCallLogs'),
  resolveSmsFn: firebase.functions().httpsCallable('resolveSms'),
  saveManualLogFn: firebase.functions().httpsCallable('saveManualLog'),
  recordMembershipFn: firebase.functions().httpsCallable('recordMembership'),
  postFacebookCommentFn: firebase.functions().httpsCallable('postFacebookComment'),
  updateUserDetailFn: firebase.functions().httpsCallable('updateUserDetail'),
  addGroupPostsAdminFn: firebase.functions().httpsCallable('addGroupPostsAdmin'),
  optOutUserFn: firebase.functions().httpsCallable('optOutUser'),
};
export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};
