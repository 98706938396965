import * as React from 'react';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { store, rrfProps } from './src/store';

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>{element}</ReactReduxFirebaseProvider>
  </Provider>
);
